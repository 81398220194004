import React from 'react';
import CommonHero from '../components/layout/CommonHero';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <CommonHero title="404: not found" />
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
);

export default NotFoundPage;
